@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');

// Set your brand colors
$blue: #4A52E8;
$pink: #fa7c91;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
$grey-lighter: #e8e8e8;
$white: #FFFFFF;

// Update Bulma's global variables
$family-sans-serif: 'Roboto'; //,sans-serif;
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $blue;
$link: $blue;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $white;
$control-border-width: 2px;
$input-border-color: $grey-lighter;
$input-shadow: none;

// Modal
$modal-content-width: 1024px;
$modal-card-head-background-color: $white;
$modal-card-foot-background-color: $white;
$modal-card-foot-border-top: 0px;

// Import Bulma
@import "../node_modules/bulma/bulma.sass";
@import "../node_modules/bulma-addons/src/sass/navigation-view.sass";
@import '~bulma-divider';
// or
// Import only what you need from Bulma
//@import '../node_modules/bulma/sass/utilities/_all.sass';
//@import '../node_modules/bulma/sass/base/_all.sass';
//@import '../node_modules/bulma/sass/elements/box.sass';
//@import '../node_modules/bulma/sass/elements/button.sass';
//@import '../node_modules/bulma/sass/elements/container.sass';
//@import '../node_modules/bulma/sass/elements/form.sass';
//@import '../node_modules/bulma/sass/elements/form.sass';
//@import '../node_modules/bulma/sass/elements/title.sass';
//@import '../node_modules/bulma/sass/components/navbar.sass';
//@import '../node_modules/bulma/sass/layout/hero.sass';
//@import '../node_modules/bulma/sass/layout/section.sass';
